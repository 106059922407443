import React from "react"
import Layout from "../../components/layout"

// The file referenced below is where most of the article's styling comes from.
import newsPostStyles from "../../styles/templates/news-post.module.css"
// If custom styling is needed, either edit the file referenced above or create a new module and reference it as below:
// import <any_variable_name> from "../../styles/templates/<name_of_file>.module.css"

// Add pictures for the article by adding more of the following, changing the path:  
import img1 from "C:/Users/Arthouse 2/Desktop/edimcf-websites/creative-caboose/src/images/news/article-2/img1.jpeg"

const Article2Page = () => {
    return (
        <Layout location="news">
        <div className={ newsPostStyles.article }>
            {/* Edit the line below to include the article title */}
            <h1>The return of the ‘Kitchen Queen’</h1>

            {/* Edit the line below to include the date in which the article was posted */}
            <h3>January 4, 2020</h3>

            {/* The section below is for a 100% width photo layout
            Any other layouts needed must be styled using CSS */}
            <div className={ newsPostStyles.landscape }>
                {/* To add more images, just add the tag below and change the variable name in-between the curly brackets */}
                <img src={ img1 } alt=""/>
            </div>

            {/* Body text goes below
            To add more, just add as many of the following as you need: */}
            <p>Cignal TV welcomes back the “Queen of the Kitchen,” seasoned actress Helen Gamboa Sotto, with the launch of the third season of “From Helen’s Kitchen” on Colours and TV5, premiering this December 19. </p>
            <p>This season kicks off with two holiday-themed episodes: Noche Buena Traditions and Family Favorites for Media Noche where Tita Helen will share holiday traditions and create their family’s signature noche buena and media noche family favorites alongside her husband, </p>
            <p>Tito Sotto, and their children: Apples, Lala, Gian and Ciara Sotto.
She’ll also be cooking with celebrity guests such as Joey de Leon, Gelli de Belen and many others. </p>
            <p>“From Helen’s Kitchen” Season 3 sees Tita Helen opening up her kitchen once again as she imparts a sense of comfort and holiday cheer through hearty and delicious meals. </p>
            <p>“We live in a world that is unlike any other. Many families are miles apart from one another and are not able to physically be together due to these unprecedented times. Through our third season, we want to bring the holiday spirit to our viewers by sharing our family’s Noche Buena dishes and traditions,” the cooking aficionado shared. “Lahat kami ng family ko ay happy na maibahagi ang kahit kaunting kasiyahan na dala ng pagluluto sa ating mga viewers.” </p>
            <p>“From Helen’s Kitchen” premieres December 19, and will air every Saturday at 8 p.m. on Colours, available on Cignal TV CH. 60 SD and CH. 202 HD. Catch-up airings will also be available on TV5 starting December 20, 7 a.m. and continues every Sunday. Viewers may also access Colours and TV5 on the Cignal Play app, available for Android and iOS users. </p>
            
            {/* Below is a button that goes back to the last page visited by the user */}
            <button onClick={() => {
                window.history.back()
            }}>Go Back</button>
        </div>
        </Layout>
    )
}

export default Article2Page;